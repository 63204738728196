import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is just a short refresher so if you more of a dive in I would recommend looking at the `}<a parentName="p" {...{
        "href": "https://mml-book.github.io/",
        "target": "_blank",
        "rel": "nofollow"
      }}><em parentName="a">{`Mathematics for Machine Learning`}</em></a>{` book, it's one of my favorites!`}</p>
    <h3>{`Derivatives`}</h3>
    <p>{`In calculus, the derivative of a function measures the sensitivity or slope of the function's result with respect to another variable.`}</p>
    <p>{`To compute derivatives we use many rules and shorthands that help us. Over time you get an idea of which ones exits pretty naturally but when you're just starting you'll need to have a `}<a parentName="p" {...{
        "href": "https://www.mathsisfun.com/calculus/derivatives-rules.html",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`table of differentiation`}</a>{` rules handy.`}</p>
    <p>{`Let's consider a simple example where we have a real-valued function `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f(x)`}</span>{`. To be precise we have a function `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f`}</span>{` that depends on one variable `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`x`}</span>{`. We can take the derivative of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f`}</span>{` with respect to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`x`}</span>{` (denoted as `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`df/dx`}</span>{` or `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f'(x)`}</span>{`) to see how sensitive the output of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f`}</span>{` is to changes in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`x`}</span>{`.`}</p>
    <div {...{
      "className": "math math-display"
    }}>{`f(x) = x^2 + 1`}</div>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{df}{dx} = 2x`}</div>
    <p>{`Given the derivative we can see that the sensitivity of the change depends on the value of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`x`}</span>{` and we could evaluate it if we wanted to. I got the derivative using something called the power rule which can be found in the aforementioned table.`}</p>
    <h3>{`Partial Derivatives`}</h3>
    <p>{`For all intensive purposes, the process of determining partial derivatives is the same as for normal derivatives with some small tweaks. The most important of which is how we treat a function of multiple variables and how we differentiate.`}</p>
    <p>{`Let's consider another function `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f`}</span>{` except this time it's `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f(x, y)`}</span>{` which means it depends on multiple variables. Considering this, we can take two different derivatives, one with respect to each of input variables which are called `}<em parentName="p">{`partial derivatives`}</em>{`. To compute these derivatives we hold all other variables as constants when integrating with respect to one.`}</p>
    <div {...{
      "className": "math math-display"
    }}>{`f(x, y) = 2xy + x + y`}</div>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial f}{\\partial x} = 2y + 1 + 0
\\quad
\\frac{\\partial f}{\\partial y} = 2x + 0 + 1`}</div>
    <p>{`As with the typical derivative, these tell us how sensitive `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`f`}</span>{` is to changes in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`x`}</span>{` and `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`y`}</span>{` but now these might actually be functions of multiple variables. We use the same differentiation rules to get these except when evaluating `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`\\partial f / \\partial x`}</span>{` we treat `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`y`}</span>{` and all other variables as if they are constant and vice versa for other variables.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      