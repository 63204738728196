import SeriesIntro from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/SeriesIntro.mdx";
import PartialDeriv from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/asides/PartialDeriv.mdx";
import grad from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/videos/grad.mp4";
import grad_problem from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/videos/grad_problem.mp4";
import hill_descent from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/videos/hill_descent.mp4";
import LinearRegressionGrad from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/asides/LinearRegressionGrad.mdx";
import loss_travel from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/videos/loss_travel.mp4";
import loss_travel_parabola from "../../../../src/content/blog/2-MachineLearning/NNFromScratch/1-GradientDescent/videos/loss_travel_parabola.mp4";
import * as React from 'react';
export default {
  SeriesIntro,
  PartialDeriv,
  grad,
  grad_problem,
  hill_descent,
  LinearRegressionGrad,
  loss_travel,
  loss_travel_parabola,
  React
};