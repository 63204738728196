import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Computing Weight Derivative`}</h3>
    <p>{`Let's start with the weight gradient because what we'll find is that it almost nearly gives us the bias derivative as well. Our goal is to compute `}<span parentName="p" {...{
        "className": "math math-inline"
      }}>{`\\partial L / \\partial w`}</span>{` which is the partial of our loss with respect to our model slope. For this, we just need to take the derivative of the terms inside the summation as all other terms are just constants.`}</p>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial L}{\\partial w} = \\frac{1}{N} \\sum^{n}_{i=1} \\frac{\\partial}{\\partial w} (y_i - (wx_i + b))^2`}</div>
    <p>{`To compute this derivative we need to use the `}<a parentName="p" {...{
        "href": "https://tutorial.math.lamar.edu/classes/calci/chainrule.aspx",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`chain rule`}</a>{`. The way to think about this is that there is an inside and outside function where the squaring of the terms is the outside and everything in the parentheses the inside function. With the chain rule we end up getting the following for the partial derivative.`}</p>
    <div {...{
      "className": "math math-display"
    }}>{`\\text{Out} = (...)^2 \\quad \\text{In} = y_i - (wx_i + b)`}</div>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial L}{\\partial w} = \\frac{\\partial \\text{Out}}{\\partial w} \\text{In} \\frac{\\partial \\text{In}}{\\partial w} `}</div>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial L}{\\partial w} = \\frac{1}{N} \\sum^{n}_{i=1} 2 (y_i - (wx_i + b)) (-x_i)`}</div>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial L}{\\partial w} = \\frac{-2}{N} \\sum^{n}_{i=1} x_i (y_i - (wx_i + b))`}</div>
    <h3>{`Computing Bias Derivative`}</h3>
    <p>{`We can follow the exact same template that we get from the chain rule for the bias term as well but the derivative of the inside term will be different.`}</p>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial L}{\\partial b} = \\frac{1}{N} \\sum^{n}_{i=1} 2 (y_i - (wx_i + b)) (-1)`}</div>
    <div {...{
      "className": "math math-display"
    }}>{`\\frac{\\partial L}{\\partial b} = \\frac{-2}{N} \\sum^{n}_{i=1} (y_i - (wx_i + b))`}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      